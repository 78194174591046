<template>
  <div>
    <AdminBreadcrumb />
    <ComingSoon />
  </div>
</template>

<script>
import AdminBreadcrumb from '../../components/shared/AdminBreadcrumb'
import ComingSoon from './components/business-rules/ComingSoon'

export default {
  name: 'AdminBusinessRulesValidation',
  components: {
    AdminBreadcrumb,
    ComingSoon,
  },
}
</script>
